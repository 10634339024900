import { render, staticRenderFns } from "./noNav.vue?vue&type=template&id=4a55c78c&"
import script from "./noNav.vue?vue&type=script&lang=js&"
export * from "./noNav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoFooter: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/InfoFooter.vue').default,PopupCigaretteCode: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/PopupCigaretteCode.vue').default,PopupCompetitiveProduct: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/PopupCompetitiveProduct.vue').default,PopupRulesAndConditions: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/PopupRulesAndConditions.vue').default})
