import { render, staticRenderFns } from "./default.vue?vue&type=template&id=530f2725&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Nav: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/Nav.vue').default,InfoFooter: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/InfoFooter.vue').default,PopupQuestionsProfile: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/PopupQuestionsProfile.vue').default,PopupPrizesProfile: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/PopupPrizesProfile.vue').default,PopupCodesProfile: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/PopupCodesProfile.vue').default,PopupRulesAndConditions: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/PopupRulesAndConditions.vue').default,Popup2ExtraCodes: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/Popup2ExtraCodes.vue').default,PopupMgm: require('/builds/2oYM8JSf/2/mcp/sk-soutez-2022/frontend/components/PopupMgm.vue').default})
